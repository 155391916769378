import axios from "@/axios.js";

/* eslint-disable */
export default {
  fetchQueriesParam(queries) {
    return new Promise((resolve, reject) => {
      axios
        .get("NexIntelligence" + queries)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchQuery(id) {
    return new Promise((resolve, reject) => {
      axios
        .get("NexIntelligence/" + id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addCampaign(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("NexIntelligence", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchPosts(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`NexIntelligence/${id}/posts`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  generatePost(id, payload, platform) {
    return new Promise((resolve, reject) => {
      const url = `NexIntelligence/${id}/generate-post/${platform}`;
      axios
        .post(url, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removePost(id, platform) {
    return new Promise((resolve, reject) => {
      const url = `NexIntelligence/${id}/remove-post/${platform}`;
      axios
        .post(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSocialMediaProfile(id, platform) {
    return new Promise((resolve, reject) => {
      axios
        .get(`NexIntelligence/${id}/profile/${platform}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deployPost(id, payload, platform) {
    return new Promise((resolve, reject) => {
      const url = `NexIntelligence/${id}/deploy/${platform}`;
      axios
        .post(url, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateCampaign(id, form, platform) {
    return new Promise((resolve, reject) => {
      const url = `NexIntelligence/${id}/update/${platform}`;
      axios
        .post(url, form)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  generateVariation(id, payload, platform) {
    return new Promise((resolve, reject) => {
      const url = `NexIntelligence/${id}/generate-variation/${platform}`;
      axios
        .post(url, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateKeywords(id, payload) {
    return new Promise((resolve, reject) => {
      const url = `NexIntelligence/${id}/update-metadata`;
      axios
        .post(url, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addKeywords(id, payload) {
    return new Promise((resolve, reject) => {
      const url = `NexIntelligence/${id}/generate-prediction`;
      axios
        .post(url, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchPrediction(id, payload) {
    return new Promise((resolve, reject) => {
      const url = `NexIntelligence/${id}/prediction`;
      axios
        .get(url, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchCrunchbase(id) {
    return new Promise((resolve, reject) => {
      const url = `NexIntelligence/${id}/crunchbase`;
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
